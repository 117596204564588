import React, { useContext } from "react"
import { SelectedProviderContext } from "../context/selected-provider-context"
import { imagePath } from "../images"
import { DateFormat } from "./date"
import styled from "@emotion/styled";

const ButtonStyled = styled.div`
  height: 60px;
  border-radius: 60px;
  background: #111;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 800;
  letter-spacing: 0.75px;
  max-width: 80%;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  width: 100%;
  border: 2px solid #fff;
`;

export const Playlist = ({ playlistData, hideMeta, showButton }) => {
  const { title, image, description, date, updated } = playlistData
  const selectedProvider = useContext(SelectedProviderContext)

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={playlistData[`url_${selectedProvider}`]}
    >
      <div className="image-container">
        <img src={imagePath(image)} alt={title} />
      </div>
      {!hideMeta && <div className="meta">
        <h2>{title}</h2>
        {description && <p>{description}</p>}
        {date && <p><DateFormat date={date} /></p>}
        {updated && <p className="last-updated">Updated: <DateFormat date={updated} /></p>}
      </div>}
      {showButton && <ButtonStyled>Open
        <svg style={{ marginLeft: `10px` }} fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" /></svg></ButtonStyled>}
    </a >
  )
}
