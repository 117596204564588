import styled from "@emotion/styled"
import React from "react"
import { mainPlaylist } from "../data/playlist-data"
import { Playlist } from "./playlist"

export const PlaylistStyled = styled.div`
  ul.playlists {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-gap: 20px;

    li {
      a {
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-auto-flow: dense;
        grid-gap: 10px;
        padding: 10px;
        border: 1px solid #eee;
        border-radius: 8px;
        background-color: #fff;
      }

      .image-container {
        border-radius: 8px;
        overflow: hidden;
        width: 100px;
        height: 100px;
        background: #f3f5f7;
      }

      img {
        margin: 0;
      }
    }
  }

  a {
    text-decoration: none;
    color: #161616;
    position: relative;
    display: block;
  }

  .meta {
    display: flex;
    justify-content: center;
    flex-direction: column;

    h2 {
      margin-bottom: 5px;
      font-size: 24px;
    }

    p {
      color: #667075;
      font-size: 14px;
    }

    .last-updated {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75em;
      line-height: 2.5;
    }
  }
`

export const Playlists = ({ playlists }) => {
  const mostRecentPlaylist = [...playlists].pop()
  const remainingWeeklyPlaylists = [...playlists]
  remainingWeeklyPlaylists.pop()
  remainingWeeklyPlaylists.reverse()

  const items = remainingWeeklyPlaylists.map(playlistData => {
    return (
      <li key={playlistData.title}>
        <Playlist playlistData={playlistData} />
      </li>
    )
  })

  return (
    <PlaylistStyled>
      <h4 className="heading-sm mb-10 mt-20">Most recent</h4>
      <ul className="playlists">
        <li>
          <Playlist playlistData={mostRecentPlaylist} />
        </li>
      </ul>
      <h4 className="heading-sm mb-10 mt-40">The main list</h4>
      <ul className="playlists">
        <li>
          <Playlist playlistData={mainPlaylist} />
        </li>
      </ul>
      <h4 className="heading-sm mb-10 mt-40">Past weekly playlists</h4>
      <ul className="playlists">{items}</ul>
    </PlaylistStyled>
  )
}
