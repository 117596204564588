import styled from "@emotion/styled"
import React, { useContext } from "react"
import { ProviderIcon } from "./provider-icon"
import { SelectedProviderContext } from "../context/selected-provider-context"

const ProvidersStyled = styled.ul`
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
  display: grid;
  grid-gap: 20px;

  .active {
    font-weight: 600;
  }

  button {
    background: #fff;
    border: 0;
    display: flex;
    width: 100%;
    height: 110px;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid #eee;
    border-radius: 4px;
  }

  svg {
    width: 200px;
  }
`

const ProviderTitle = styled.h4`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  svg {
    width: 120px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      border: 0;
      background: 0;
      font-size: 0.75em;
      white-space: nowrap;
      background: #151515;
      color: #fff;
      border-radius: 8px;
      padding: 4px 8px;
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 700;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .icon {
    width: 40px;
  }
`

export const Providers = ({
  providers,
  handleProviderClick,
}) => {
  const selectedProvider = useContext(SelectedProviderContext)
  const items = providers.map(({ title, id }) => (
    <li className={selectedProvider === id ? `active` : ``} key={id}>
      <button onClick={() => handleProviderClick(id)}>
        <ProviderIcon provider={id} />
      </button>
    </li>
  ))

  let provider = providers.filter(({ id }) => selectedProvider === id)
  provider = provider[0]

  return (
    <>
      {!selectedProvider && (
        <>
          <h2 className="my-40 text-center">Select your provider</h2>
          <ProvidersStyled>{items}</ProvidersStyled>
        </>
      )}
      {selectedProvider && (
        <>
          <ProviderTitle>
            <div className="title">
              <div className="d-flex align-items-center" onClick={() => handleProviderClick(null)}>
                <span className="heading-sm">Your provider:{" "}</span>
                <span style={{ marginLeft: `10px` }}>
                  <ProviderIcon provider={provider.id} />
                </span>
              </div>
              <button onClick={() => handleProviderClick(null)}>change</button>
            </div>
          </ProviderTitle>
        </>
      )}
    </>
  )
}
