import React from "react"

export const DateFormat = ({ date }) => {
  if (!date) {
    return <></>;
  }
  let formattedDate
  const dateObj = new Date(date)
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  formattedDate = new Intl.DateTimeFormat("en-US", options).format(dateObj)
  return <>{formattedDate}</>
}