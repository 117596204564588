export const providers = [
  {
    id: `spotify`,
    title: `Spotify`,
  },
  {
    id: `apple`,
    title: `Apple Music`,
  },
]

export const mainPlaylist = {
  title: `JAMZ ONLY`,
  image: `./playlists/jamz-only.jpg`,
  url_spotify: `https://open.spotify.com/playlist/3riE10oz9jll4H3ZF0igDK`,
  url_apple: `https://music.apple.com/ca/playlist/jamz-only/pl.u-qxylEYXt2xgjG9`,
  description: `The top 100 from the weekly playlists. Modern indietronica.`,
  updated: `2020-01-19T08:00:00.000Z`,
}
