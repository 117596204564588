import React, { useEffect } from "react"

export function usePersistedState(key, defaultValue) {
  const [state, setState] = React.useState(() => {
    if (typeof window !== "undefined") {
      return JSON.parse(localStorage.getItem(key)) || defaultValue
    } else {
      return defaultValue
    }
  })
  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem(key, JSON.stringify(state))
    }
  }, [key, state])
  return [state, setState]
}
